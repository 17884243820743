
import React, { useContext, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import RatioButton from '../../subComponents/gacha/RatioButton'
import PurchaseButton from '../../subComponents/gacha/PurchaseButton'
import AuthContext from '../../utils/auth/AuthContext';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import LoadingScreen from '../loading/LoadingScreen';
type PlayBeforeProps = {
  teamColor: string,
  tickets: number,
  teamName: string,
  product_code: string,
  setProductValiationId: React.Dispatch<React.SetStateAction<string>>,
  setRarityName: React.Dispatch<React.SetStateAction<string>>,
  setProductValiationName: React.Dispatch<React.SetStateAction<string>>,
  setRareEffect: React.Dispatch<React.SetStateAction<string>>,
  setTickets: React.Dispatch<React.SetStateAction<number>>;
}

export const PlayBefore: React.FC<PlayBeforeProps> = ({ teamColor, tickets, teamName, product_code, setProductValiationId, setRarityName, setProductValiationName, setRareEffect, setTickets }) => {
  const { authentication } = useContext(AuthContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className='mainSection'>
        {windowWidth >= 500 ?
          <img className='mx-auto d-block w-100' src={`/image/${teamName}/${product_code}/product.jpg`} />
          :
          <img className='mx-auto d-block w-100' src={`/image/${teamName}/${product_code}/product-sm.jpg`} />
        }
      </div>

      <div className="bg-cleargray p-2">
        <div className="w-250 bg-white text-center py-0 px-2 mx-auto border rounded-pill shadow">
          {
            Number(tickets) > 0 ?
              (
                authentication ?
                  <>
                    <span>所持チケット：{tickets}</span>
                    <PurchaseButton
                      teamColor={teamColor}
                      product_code={product_code}
                      setProductValiationId={setProductValiationId}
                      setProductValiationName={setProductValiationName}
                      setRarityName={setRarityName}
                      setRareEffect={setRareEffect}
                      setTickets={setTickets}
                      setLoading={setLoading}
                    />
                  </>
                  :
                  <>
                    <Button variant="primary" href="/login" className={`bg-${teamColor}-primary w-75 mx-auto m-2`}>購入する</Button>
                  </>
              )
              :
              (
                authentication ?
                  <>
                    <span className="text-danger fw-bold">所持チケット：{tickets}</span>
                    <Link className={`bg-${teamColor}-primary text-white btn w-75 mx-auto mt-4 px-2 py-1`} to="/ticket">チケットを購入する</Link>
                  </>
                  :
                  <>
                    <Button variant="primary" href="/login" className={`bg-${teamColor}-primary w-75 mx-auto m-2`}>購入する</Button>
                  </>
              )
          }
          <RatioButton teamColor={teamColor} product_code={product_code} />
          <p className="text-center"><Link to="/transaction">特定商取引法に基づく表記</Link></p>
        </div>
      </div>
    </>
  );
}

export default PlayBefore;
